import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { createPinia } from 'pinia'
import vSelect from 'vue-select'
import {useUserStore} from "@/stores/user"
import {usePanelStore} from "@/stores/panel"

// import tinymce from 'vue-tinymce-editor'


axios.defaults.withCredentials = true;

let token = null

if (localStorage.getItem('token')){
    token = localStorage.getItem('token')
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

const app = createApp(App)

const pinia = createPinia()

app.use(pinia)
app.use(router)
// app.component('tinymce', tinymce)

app.use(VueAxios, axios)

app.config.globalProperties.$panel = usePanelStore()

app.config.globalProperties.$panel.merchant = localStorage.getItem('merchSlug') || null;
app.config.globalProperties.$panel.merchant_id = localStorage.getItem('merchId') || null;
// app.config.globalProperties.$panel.$state.server = app.config.globalProperties.$server = 'http://localhost/'
app.config.globalProperties.$panel.$state.server = app.config.globalProperties.$server = 'https://api.menu.true-false.ru/'
app.config.globalProperties.$user =  useUserStore()

app.config.globalProperties.$user.$state.token = token

app.component('v-select', vSelect)
app.mount('#app')
