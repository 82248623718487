import {defineStore} from 'pinia'
import axios from "axios";
import {usePanelStore} from "@/stores/panel";

export const useUserStore = defineStore('user', {
    state: () => ({
        tryAuth: false,
        role: null,
        admin: false,
        login: null,
        email: null,
        token: null
    }),
    getters: {
        authorized: (state) => {
            return state.email !== null
        },
    },
    actions: {
        authorize() {
            const server = usePanelStore().$state.server


            this.tryAuth = true


            return axios.get(server + 'admin/permission').then(res => {
                this.role = res.data.role

                usePanelStore().load(res.data)

                this.login = res.data.login
                this.email = res.data.email

                if (res.data['is_admin'])
                    this.admin = true

                console.log(res
                )

                return true

            }).catch(() => false )

        },

        logOut() {


            localStorage.removeItem('token')
            delete axios.defaults.headers.common['Authorization']



            this.tryAuth = false
            this.role =  null
            this.admin = false
            this.login = null
            this.email = null
            this.token = null

        },
        logIn(email, password) {
            const server = usePanelStore().$state.server

            return axios.post(server + 'admin/login', {email, password}).then(res => {
                this.token = res.data.token
                localStorage.setItem('token', this.token.trim())
                axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`

                return true
            }).catch(()=> false)
        }
    },
})
