<template>
    <router-view v-if="!$panel.$state.loading"></router-view>
    <loading v-else></loading>
</template>

<script>
import loading from "@/components/loading.vue";
import {defineComponent, inject} from "vue";

export default defineComponent({
  name: 'App',
  components: {
    loading
  },
  mounted() {
  }
})
</script>


<style src="@/assets/main.scss" lang="scss"></style>
