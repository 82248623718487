import {createRouter, createWebHistory} from 'vue-router'
import {useUserStore} from "@/stores/user";
import {usePanelStore} from "@/stores/panel";

const routes = [
    {
        path: '/',
        component: () => import('@/views/Home.vue'),
        meta: {
            merchant: true
        },
        beforeEnter(){
            const userStore = useUserStore()

            if (userStore.$state.tryAuth && userStore.authorized){
                if (userStore.$state.admin) {
                    return '/shops'
                }else
                    return '/panel'
            }else
                return '/login'
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/Login.vue'),
        beforeEnter(){
            const userStore = useUserStore()

            if (userStore.$state.tryAuth && userStore.authorized)
                return '/'
            else
                return true
        }
    },
    {
        path: '/shops',
        component: () => import('@/views/Shops.vue'),
        meta: {
            admin: true,
        }
    },
    {
        name: 'panel',
        path: '/panel/',
        component: () => import('@/views/Panel/index.vue'),
        meta: {
            merchant: true
        },
        children: [
            {
                name: 'panelElement',
                path: ':route?/:action?/:id?',
                component: () => import('@/views/Panel/index.vue')
            }
        ],
        beforeEnter() {
            const userStore = useUserStore()
            const panelStore = usePanelStore()

            if (userStore.$state.tryAuth && userStore.authorized){
                if (!panelStore.$state.merchant){
                    if (userStore.$state.admin)
                        return '/shops'
                    else
                        return false
                }else {
                    return true
                }
            }
        }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})


router.beforeEach(async (to, from) => {
    const userStore = useUserStore()

    if (!userStore.$state.token)
        return to.name !== 'Login' ? '/login' : true


    if (!userStore.$state.tryAuth) {
        const panel = usePanelStore()

        panel.$state.loading = true
        let authStatus

        try {
            authStatus = await userStore.authorize()
        }catch (e){}

        panel.$state.loading = false


        if (!authStatus) {
            return to.name !== 'Login' ? '/login' : true
        }else if (to.name === 'Login'){

            if (userStore.$state.admin)
                return '/shops'
            else
                return '/panel'
        }

    } else if (!userStore.authorized) {
        return to.name !== 'Login' ? '/login' : true
    }
})

export default router
