import { defineStore } from 'pinia'

export const usePanelStore = defineStore('panel', {
    state: () => ({
        stand: null,
        stand_url: '',
        stand_id: null,
        merchant: null,
        loading: false,
        merchant_id: null,
        server: 'http://localhost:8080/'
    }),
    getters: {
        merchantApi(state) {
            return state.server + 'admin/' + state.merchant+'/'
        },
        standApi(state) {
            return state.server + 'admin/' + state.merchant+'/'+state.stand+'/'
        }
    },
    actions: {
        load(data) {
            this.stand = data.stand
            this.stand_id = data.stand_id
            this.merchant = data.merchant
            this.merchant_id = data.merchant_id
        },
        setMerchant(merchant) {
            this.merchant = merchant.slug
            this.merchant_id = merchant.id
        },
        setStand(stand) {
            this.stand_id= stand.id
            this.stand = stand.slug
            this.stand_url = 'https://'+ stand.domain + '.menu.true-false.ru/'
        }
    },
})
